import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom"
import Home from "./pages/home";
import LauncherPage from "./pages/launcherPage";
import SettingPage from "./pages/settingPage";
import UtilPage from "./pages/utilPage";
import OsPage from "./pages/osPage";
import NasPage from "./pages/nas";

export default function Router() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/launcherPage" element={<LauncherPage />} />
          <Route path="/settingPage" element={<SettingPage />} />
          <Route path="/utilPage" element={<UtilPage />} />
          <Route path="/osPage" element={<OsPage />} />
          <Route path="/nasPage" element={<NasPage />} />
        </Routes>
    </BrowserRouter>
    );
  }

