import React from "react";
import { ListGroup } from 'react-bootstrap';

export default function launcherPage() {
  
  const url = "http://221.154.163.203/download/apk/"

  const downloadFile = (fileName) => {
      var a = document.createElement("a");
      
      a.setAttribute('href', url + fileName);
      a.setAttribute('download', fileName);
      a.setAttribute('target', '_blank');
      a.click();

    return true;
    
  }

  return (
    <body>
        <div>
          <ListGroup>
              <ListGroup.Item variant="primary" style={{fontSize:30}}>런처 파일 목록</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("Badagiljabi13_1.3.0.apk")} style={{fontSize:20}}>13인치 바다길잡이 1.3.0(선박용)</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() => downloadFile("단말기 런처 (Ver 1.0.1).apk")} style={{fontSize:20}}>13인치 런처 설치 1.0.1(배포용)</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("BadaGiljabi_2.3.0.apk")} style={{fontSize:20}}>바다길잡이 2.3.0</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("BadaGiljabi_2.2.0.apk")} style={{fontSize:20}}>바다길잡이 2.2.0</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("BadaGiljabi_2.1.12.apk")} style={{fontSize:20}}>바다길잡이 2.1.12(선박용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() =>  downloadFile("BadaGiljabi_2.1.11_선박용")} style={{fontSize:20}}>바다길잡이 2.1.11(선박용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.9")} style={{fontSize:20}}>바다길잡이 2.1.9(선박용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.7_ship.apk")} style={{fontSize:20}}>바다길잡이 2.1.7(선박용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.7_promotion.apk")} style={{fontSize:20}}>바다길잡이 2.1.7(홍보용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.5_ship.apk")} style={{fontSize:20}}>바다길잡이 2.1.5(선박용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.5_promotion.apk")} style={{fontSize:20}}>바다길잡이 2.1.5(홍보용)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("230515_BadaGiljabi_2.1.6_local.apk")} style={{fontSize:20}}>바다길잡이 2.1.6(권역센터)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("230628_BadaGiljabi_2.1.6_center.apk")} style={{fontSize:20}}>230628_바다길잡이 2.1.6(권역센터)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("230628_BadaGiljabi_2.1.6_centers.apk")} style={{fontSize:20}}>230628_바다길잡이 2.1.6(중앙센터)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() =>  downloadFile("BadaGiljabi_2.1.7_ship_center2.apk")} style={{fontSize:20}}>바다길잡이 2.1.7(alarmcenter)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() =>  downloadFile("BadaGiljabi_2.1.10.apk")} style={{fontSize:20}}>바다길잡이 2.1.10</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() =>  downloadFile("BadaGiljabi_2.1.11_중앙센터.apk")} style={{fontSize:20}}>바다길잡이 2.1.11(중앙센터)</ListGroup.Item>
              <ListGroup.Item variant="info" onClick={() => downloadFile("BadaGiljabi_2.1.11_내부망.apk")} style={{fontSize:20}}>바다길잡이 2.1.11(내부망)</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("Badagiljabi_Recovery.apk")} style={{fontSize:20}}>바다길잡이(Recovery)</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("BadaGiljabi_enav.apk")} style={{fontSize:20}}>바다길잡이 eNav</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() => downloadFile("BadaGiljabi_alarm.apk")} style={{fontSize:20}}>바다길잡이 eNav 알람</ListGroup.Item>




              
              <ListGroup.Item variant="danger" onClick={() => downloadFile("단말기 런처 설치 (인증용).apk")} style={{fontSize:20}}>13인치 런처 설치(인증용)</ListGroup.Item>
            </ListGroup>
        </div>
    </body>

  );
}
